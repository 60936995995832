import { Button, Stack, Typography, Link as MuiLink } from "@mui/material";
import { Link, useNavigation } from "react-router-dom";
import FrameBox from "../../ui/containers/FrameBox";
import { Box, styled } from "@mui/system";
import CenteredContentBox from "../../ui/containers/CenteredContentBox";
import { ReactComponent as Separator }
	from "../../assets/decorative/separator-hor.svg";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { useDispatch } from "react-redux";
import { openScanner, openListSetup } from "../../store/utils-slice";
import useGameSettings from "../../hooks/use-game-settings";
import Loading from "../../ui/Loading";
import RippedBox from "../../ui/containers/RippedBox";
import AppPromo from "../AppPromo";
import FrameDialog from "../../ui/containers/FrameDialog";
import FrameHeader from "../../ui/containers/FrameHeader";
import FrameDialogContent from "../../ui/containers/FrameDialogContent";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import NewReleasesIcon from '@mui/icons-material/NewReleases';

const GameLogo = styled("img")(({ theme }) => ({
	height: "90px",
	[theme.breakpoints.only("xs")]: {
		height: "70px",
	},
}));


const Line = styled(Separator)(({ theme }) => ({
	height: "40px",
	fill: "currentColor",
	padding: theme.spacing(0, 0, 3, 0),
}));


function MainGameMenu({ game }) {
	const { slug, name } = game;
	const { getGameSettings } = useGameSettings();
	const { logo } = getGameSettings(slug);
	const dispatch = useDispatch();
	const { state } = useNavigation();
	const [newsOpen, setNewsOpen] = useState(false);

	function handleNewList() {
		dispatch(openListSetup());
	}

	function handleScannerOpen() {
		dispatch(openScanner());
	}

	if (state === "loading") {
		return (
			<CenteredContentBox>
				<Loading />
			</CenteredContentBox>
		);
	}

	return (
		<>
			<CenteredContentBox>
				<Stack spacing={2} alignItems="center">
					<Button
						variant="contained"
						size="large"
						onClick={() => setNewsOpen(true)}
						startIcon={<NewReleasesIcon />}
						sx={{width: 230, marginBottom: 2}}
					>
						News
					</Button>
					<FrameBox
						frameStyle="frame1"
						bgcolor="darkBgr.main"
						color="darkBgr.contrastText"
					>
						<Stack spacing={2} m={{ xs: 5, sm: 9 }}>
							<GameLogo src={logo} alt={name} />
							<Line />
							<Button
								component={Link}
								to="factions"
								variant="contained"
								size="large"
							>
								Browse Factions
							</Button>
							<Button variant="contained" size="large" onClick={handleNewList}>
								Prepare for War
							</Button>
							<Button
								variant="contained"
								size="large"
								onClick={handleScannerOpen}
								startIcon={<QrCodeScannerIcon />}
							>
								Scan Card
							</Button>
						</Stack>
					</FrameBox>
					<Box pt={4}>
						<AppPromo />
					</Box>
				</Stack>
			</CenteredContentBox>
			<FrameDialog
				open={newsOpen}
				responsive
				onClose={() => setNewsOpen(false)}
				actions={[
					{
						id: "Cancel",
						icon: CloseIcon,
						handler: () => setNewsOpen(false),
					}
				]}
			>
				<FrameHeader title="News" />
				<FrameDialogContent>
					<Typography variant="h4" align="center" gutterBottom>
						Conquest World Champion for 2024 is crowned!
					</Typography>
					<Typography gutterBottom>
					Para Bellum Wargames, the developer and publisher of the fantasy Regimental Miniature Wargame “Conquest: The Last Argument of Kings” and Skirmish system “Conquest: First Blood”, held it’s FIRST EVER World Championship for OP Season 3, on the Greek Island of Chios! 16 of the world’s top players traveled to Greece to play and enjoy the company of other top players on the beach!
					</Typography>
					<Typography gutterBottom>
						<Box sx={{fontStyle: "italic", display: "inline"}}>“Our first World Championship event exceeded our expectations. Having world class gamers playing Conquest on an island that is part of my family’s heritage has meant the world to me. As a Gamer who grew up here, getting to experience our island with others who love to play Conquest is a dream”</Box> says Stavros Halkias, Founder and Creative Director.
					</Typography>
					<Typography gutterBottom>
						<strong>The awards went to:</strong>
						<ul>
							<li><strong>Best painted:</strong> Kilian Sieutat-Lacaze's Hundred Kingdoms Army</li>
							<li><strong>Third place:</strong> Karl Swanson – Spires</li>
							<li><strong>Second place:</strong> Pawel Przybysz – W’adrhŭn</li>
							<li><strong>2024 World Champion:</strong> Mateusz Podwysocki – City States</li>
						</ul>
					</Typography>
					<Typography gutterBottom>
						You can find the 16 finalists and their lists <MuiLink href="https://www.para-bellum.com/world-finals-2024-army-lists/" target="_blank" rel="noopener" sx={{fontWeight: "bold"}}>here</MuiLink>.
					</Typography>
					<Typography gutterBottom>
						All participants had a great time, ate fabulous Greek BBQ, enjoyed swims in the Aegean Sea and left with the tourist classic T-Shirt – albeit a very exclusive Chios World Championship T-shirt that I am sure they will be looking forward to showing off in their home community.
					</Typography>
					<Typography gutterBottom>
						Our Road to Chios 2025 has already begun, with events already being hosted as World Qualifying events continuing through the end of June. For our new season 4 Chios World Championship, we will be inviting the top First Blood players for the first time and a great crew of The Last Argument of Kings players since we will host two tournaments in Greece in September. Will Mateusz repeat…. Stay tuned!
					</Typography>
					<Typography gutterBottom>
						For more information about the Road to Chios Season 4, please link <MuiLink href="https://www.para-bellum.com/road-to-chios-season-4/" target="_blank" rel="noopener" sx={{fontWeight: "bold"}}>here</MuiLink>!
					</Typography>
					<Typography gutterBottom>
						Retailers looking to include their Conquest Community should work with their Vanguard to begin OP events. To learn more about the Vanguard program and to participate in OP events, please coordinate with your Retail Relationship Manager or contact vanguards@para-bellum.com
					</Typography>
				</FrameDialogContent>
			</FrameDialog>
		</>
	);
}


export default MainGameMenu;