export const TLAOK_DIRECTIVES = [
	{
		"key": "aberration",
		"value": "Aberration"
	},
	{
		"key": "accelerated-hibernation",
		"value": "Accelerated Hibernation"
	},
	{
		"key": "acolyte",
		"value": "Acolyte"
	},
	{
		"key": "animate-vessel",
		"value": "Animate Vessel"
	},
	{
		"key": "apostate",
		"value": "Apostate"
	},
	{
		"key": "arcing-fire",
		"value": "Arcing Fire"
	},
	{
		"key": "armor-piercing",
		"value": "Armor Piercing X"
	},
	{
		"key": "weapon-master",
		"value": "Weapon  Master"
	},
	{
		"key": "armsmaster",
		"value": "Armsmaster"
	},
	{
		"key": "aspect-of-fenrir",
		"value": "Aspect of Fenrir"
	},
	{
		"key": "assault-preceptor",
		"value": "Assault Preceptor"
	},
	{
		"key": "aura-of-death",
		"value": "Aura of Death X"
	},
	{
		"key": "barrage",
		"value": "Barrage X"
	},
	{
		"key": "bastion",
		"value": "Bastion X"
	},
	{
		"key": "bear-aspect",
		"value": "Bear"
	},
	{
		"key": "bellowing-roar",
		"value": "Bellowing Roar"
	},
	{
		"key": "biotic-renewal",
		"value": "Biotic Renewal"
	},
	{
		"key": "blackflame-coruscation",
		"value": "Blackflame Coruscation"
	},
	{
		"key": "blasphemous-power",
		"value": "Blasphemous Power"
	},
	{
		"key": "blessed",
		"value": "Blessed"
	},
	{
		"key": "blood-of-the-einherjar",
		"value": "Blood of the Einherjar"
	},
	{
		"key": "bloodlust",
		"value": "Bloodlust"
	},
	{
		"key": "blurred-vision",
		"value": "Blurred Vision"
	},
	{
		"key": "bodyguards",
		"value": "Bodyguards"
	},
	{
		"key": "bravery",
		"value": "Bravery"
	},
	{
		"key": "broken-ground",
		"value": "Broken Ground"
	},
	{
		"key": "brutal-impact",
		"value": "Brutal Impact X"
	},
	{
		"key": "burnout",
		"value": "Burnout"
	},
	{
		"key": "cacophony",
		"value": "Cacophony"
	},
	{
		"key": "call-fog",
		"value": "Call Fog"
	},
	{
		"key": "call-the-hunt",
		"value": "Call the Hunt"
	},
	{
		"key": "call-the-storm",
		"value": "Call the Storm"
	},
	{
		"key": "calm-strategist",
		"value": "Calm Strategist"
	},
	{
		"key": "captain",
		"value": "Captain"
	},
	{
		"key": "catabolic-node",
		"value": "Catabolic Node"
	},
	{
		"key": "catalytic-rupture",
		"value": "Catalytic Rupture"
	},
	{
		"key": "champion",
		"value": "Champion"
	},
	{
		"key": "cleave",
		"value": "Cleave X"
	},
	{
		"key": "coordinated-assault",
		"value": "Coordinated Assault"
	},
	{
		"key": "coruscation",
		"value": "Coruscation"
	},
	{
		"key": "count-palatine",
		"value": "Count Palatine"
	},
	{
		"key": "counter-attack",
		"value": "Counter-Attack"
	},
	{
		"key": "crescendo",
		"value": "Crescendo"
	},
	{
		"key": "crow-aspect",
		"value": "Crow"
	},
	{
		"key": "dark-cenotaph",
		"value": "Dark Cenotaph"
	},
	{
		"key": "dark-immolation",
		"value": "Dark Immolation"
	},
	{
		"key": "dark-shepherd",
		"value": "Dark Shepherd"
	},
	{
		"key": "dark-supplication",
		"value": "Dark Supplication"
	},
	{
		"key": "dauntless",
		"value": "Dauntless"
	},
	{
		"key": "deadly-blades",
		"value": "Deadly Blades"
	},
	{
		"key": "deadly-shot",
		"value": "Deadly Shot"
	},
	{
		"key": "deadshots",
		"value": "Deadshots"
	},
	{
		"key": "decay",
		"value": "Decay X"
	},
	{
		"key": "devout",
		"value": "Devout"
	},
	{
		"key": "dismay",
		"value": "Dismay"
	},
	{
		"key": "dissonance",
		"value": "Dissonance"
	},
	{
		"key": "divine-sanction",
		"value": "Divine Sanction"
	},
	{
		"key": "dominate",
		"value": "Dominate"
	},
	{
		"key": "double-time",
		"value": "Double Time"
	},
	{
		"key": "dragon-aspect",
		"value": "Dragon"
	},
	{
		"key": "drain-will",
		"value": "Drain Will"
	},
	{
		"key": "dread",
		"value": "Dread"
	},
	{
		"key": "drillmaster",
		"value": "Drillmaster"
	},
	{
		"key": "drum-beat",
		"value": "Drum Beat"
	},
	{
		"key": "duelist",
		"value": "Duelist"
	},
	{
		"key": "dynastic-alliances",
		"value": "Dynastic Alliances"
	},
	{
		"key": "eagle-aspect",
		"value": "Eagle"
	},
	{
		"key": "earth-to-mud",
		"value": "Earth to Mud"
	},
	{
		"key": "elemental-puissance",
		"value": "Elemental Puissance"
	},
	{
		"key": "encase",
		"value": "Encase"
	},
	{
		"key": "enrage",
		"value": "Enrage"
	},
	{
		"key": "errant-of-the-order-of-the-shield",
		"value": "Errant of the Order of the Shield"
	},
	{
		"key": "eruption",
		"value": "Eruption"
	},
	{
		"key": "essence-transfer",
		"value": "Essence Transfer"
	},
	{
		"key": "exemplar",
		"value": "Exemplar"
	},
	{
		"key": "exhortation-of-eternal-faith",
		"value": "Exhortation of Eternal Faith"
	},
	{
		"key": "fanatic",
		"value": "Fanatic"
	},
	{
		"key": "fanatical-devotion",
		"value": "Fanatical Devotion"
	},
	{
		"key": "fearless",
		"value": "Fearless"
	},
	{
		"key": "fearsome",
		"value": "Fearsome"
	},
	{
		"key": "fervor",
		"value": "Fervor"
	},
	{
		"key": "fiend-hunter",
		"value": "Fiend Hunter"
	},
	{
		"key": "fire-and-advance",
		"value": "Fire and Advance"
	},
	{
		"key": "fire-dart",
		"value": "Fire Dart"
	},
	{
		"key": "fireball",
		"value": "Fireball"
	},
	{
		"key": "flame-wall",
		"value": "Flame Wall"
	},
	{
		"key": "flamecaster",
		"value": "Flamecaster"
	},
	{
		"key": "flank",
		"value": "Flank"
	},
	{
		"key": "flawless-drill",
		"value": "Flawless Drill"
	},
	{
		"key": "flawless-strikes",
		"value": "Flawless Strikes"
	},
	{
		"key": "fluid-formation",
		"value": "Fluid Formation"
	},
	{
		"key": "flurry",
		"value": "Flurry"
	},
	{
		"key": "flux-powered",
		"value": "Flux-Powered"
	},
	{
		"key": "fly",
		"value": "Fly"
	},
	{
		"key": "font-of-dark-power",
		"value": "Font of Dark Power"
	},
	{
		"key": "forged-in-battle",
		"value": "Forged in Battle"
	},
	{
		"key": "fruit-of-the-golden-tree",
		"value": "Fruit of the Golden Tree"
	},
	{
		"key": "fury",
		"value": "Fury X"
	},
	{
		"key": "gift-of-the-einherjar",
		"value": "Gift of the Einherjar"
	},
	{
		"key": "glimmers-of-a-golden-age",
		"value": "Glimmers of a Golden Age"
	},
	{
		"key": "glorious-charge",
		"value": "Glorious Charge"
	},
	{
		"key": "glory-of-valhalla",
		"value": "Glory of Valhalla"
	},
	{
		"key": "grant-virulence",
		"value": "Grant Virulence"
	},
	{
		"key": "guide",
		"value": "Guide"
	},
	{
		"key": "hardened",
		"value": "Hardened X"
	},
	{
		"key": "harvest-essence",
		"value": "Harvest Essence"
	},
	{
		"key": "hazlia-s-touch",
		"value": "Hazlia's Touch"
	},
	{
		"key": "heavenly-blessing",
		"value": "Heavenly Blessing"
	},
	{
		"key": "hela-s-caress",
		"value": "Hela's Caress"
	},
	{
		"key": "herald-of-fire",
		"value": "Herald of Fire"
	},
	{
		"key": "herald-of-magma",
		"value": "Herald of Magma"
	},
	{
		"key": "herald-of-stone",
		"value": "Herald of Stone"
	},
	{
		"key": "hetairos",
		"value": "Hetairos"
	},
	{
		"key": "holy-fire",
		"value": "Holy Fire"
	},
	{
		"key": "hone-blades",
		"value": "Hone Blades"
	},
	{
		"key": "huntress",
		"value": "Huntress"
	},
	{
		"key": "hymn-of-the-victor",
		"value": "Hymn of the Victor"
	},
	{
		"key": "ice-armor",
		"value": "Ice Armor"
	},
	{
		"key": "immolation",
		"value": "Immolation"
	},
	{
		"key": "impact",
		"value": "Impact X"
	},
	{
		"key": "in-the-presence-of-mnemancers",
		"value": "In the Presence of Mnemancers"
	},
	{
		"key": "indomitable",
		"value": "Indomitable"
	},
	{
		"key": "induced-vigor",
		"value": "Induced Vigor"
	},
	{
		"key": "insanity",
		"value": "Insanity"
	},
	{
		"key": "inspired",
		"value": "Inspired"
	},
	{
		"key": "inspiring-presence",
		"value": "Inspiring Presence"
	},
	{
		"key": "irregular",
		"value": "Irregular"
	},
	{
		"key": "iron-discipline",
		"value": "Iron Discipline"
	},
	{
		"key": "kindle-courage",
		"value": "Kindle Courage"
	},
	{
		"key": "leader",
		"value": "Leader"
	},
	{
		"key": "legacies-of-the-ark",
		"value": "Legacies of the Ark"
	},
	{
		"key": "lethal-demise",
		"value": "Lethal Demise"
	},
	{
		"key": "linebreaker",
		"value": "Linebreaker"
	},
	{
		"key": "liquid-anathema",
		"value": "Liquid Anathema"
	},
	{
		"key": "living-legend",
		"value": "Living Legend"
	},
	{
		"key": "loose-formation",
		"value": "Loose Formation"
	},
	{
		"key": "magmatic-seep",
		"value": "Magmatic Seep"
	},
	{
		"key": "master-hashashin",
		"value": "Master Hashashin"
	},
	{
		"key": "mend-flesh",
		"value": "Mend Flesh"
	},
	{
		"key": "mist-weave",
		"value": "Mist Weave"
	},
	{
		"key": "mnemancer-apprentice",
		"value": "Mnemancer Apprentice"
	},
	{
		"key": "murderous-volley",
		"value": "Murderous Volley"
	},
	{
		"key": "neophyte",
		"value": "Neophyte"
	},
	{
		"key": "ninuah-s-tears",
		"value": "Ninuah's Tears"
	},
	{
		"key": "null-mage",
		"value": "Null Mage"
	},
	{
		"key": "obdurate-bone-and-stone",
		"value": "Obdurate Bone and Stone"
	},
	{
		"key": "oblivious",
		"value": "Oblivious"
	},
	{
		"key": "opportunists",
		"value": "Opportunists"
	},
	{
		"key": "optio",
		"value": "Optio"
	},
	{
		"key": "overcharge",
		"value": "Overcharge"
	},
	{
		"key": "overrun",
		"value": "Overrun"
	},
	{
		"key": "parry",
		"value": "Parry"
	},
	{
		"key": "personalized-epigenetic-triggers",
		"value": "Personalized Epigenetic Triggers"
	},
	{
		"key": "phalanx",
		"value": "Phalanx"
	},
	{
		"key": "pheromantic-compulsion",
		"value": "Pheromantic Compulsion"
	},
	{
		"key": "pheromantic-drive",
		"value": "Pheromantic Drive"
	},
	{
		"key": "pheromone-gland-burst",
		"value": "Pheromone Gland Burst"
	},
	{
		"key": "powerful-physique",
		"value": "Powerful Physique"
	},
	{
		"key": "precise-shot",
		"value": "Precise Shot"
	},
	{
		"key": "priest",
		"value": "Priest X"
	},
	{
		"key": "princeps",
		"value": "Princeps"
	},
	{
		"key": "profane-reliquary",
		"value": "Profane Reliquary"
	},
	{
		"key": "protective-glyphs",
		"value": "Protective Glyphs"
	},
	{
		"key": "proximity-sensors",
		"value": "Proximity Sensors"
	},
	{
		"key": "pyroclast",
		"value": "Pyroclast"
	},
	{
		"key": "quicksilver-strike",
		"value": "Quicksilver Strike"
	},
	{
		"key": "raid-leader",
		"value": "Raid Leader"
	},
	{
		"key": "rake-attack",
		"value": "Rake Attack"
	},
	{
		"key": "rapid-deployment",
		"value": "Rapid Deployment"
	},
	{
		"key": "rapid-volley",
		"value": "Rapid Volley"
	},
	{
		"key": "regeneration",
		"value": "Regeneration X"
	},
	{
		"key": "relentless",
		"value": "Relentless"
	},
	{
		"key": "relentless-blows",
		"value": "Relentless Blows"
	},
	{
		"key": "relentless-drill",
		"value": "Relentless Drill"
	},
	{
		"key": "relic-bearer",
		"value": "Relic Bearer"
	},
	{
		"key": "reshape-destiny",
		"value": "Reshape Destiny"
	},
	{
		"key": "resolve",
		"value": "Resolve"
	},
	{
		"key": "rider",
		"value": "Rider"
	},
	{
		"key": "righteous-annihilation",
		"value": "Righteous Annihilation"
	},
	{
		"key": "rime-storm",
		"value": "Rime Storm"
	},
	{
		"key": "rock-shaping",
		"value": "Rock Shaping"
	},
	{
		"key": "roots-of-stone",
		"value": "Roots of Stone"
	},
	{
		"key": "saint-s-favor",
		"value": "Saint's Favor"
	},
	{
		"key": "savage",
		"value": "Savage"
	},
	{
		"key": "scholar-of-the-profane",
		"value": "Scholar of the Profane"
	},
	{
		"key": "school-of-air",
		"value": "School of Air"
	},
	{
		"key": "school-of-earth",
		"value": "School of Earth"
	},
	{
		"key": "earth-school",
		"value": "Earth School"
	},
	{
		"key": "magma-school",
		"value": "Magma School"
	},
	{
		"key": "school-of-fire",
		"value": "School of Fire"
	},
	{
		"key": "fire-school",
		"value": "Fire School"
	},
	{
		"key": "school-of-water",
		"value": "School of Water"
	},
	{
		"key": "seasoned-veteran",
		"value": "Seasoned Veteran"
	},
	{
		"key": "seeking-winds",
		"value": "Seeking Winds"
	},
	{
		"key": "seize-the-day",
		"value": "Seize the Day"
	},
	{
		"key": "servite",
		"value": "Servite"
	},
	{
		"key": "shield",
		"value": "Shield"
	},
	{
		"key": "shield-biter",
		"value": "Shield Biter"
	},
	{
		"key": "shock",
		"value": "Shock"
	},
	{
		"key": "shock-assault",
		"value": "Shock Assault"
	},
	{
		"key": "siphon-strength",
		"value": "Siphon Strength"
	},
	{
		"key": "skald",
		"value": "Skald"
	},
	{
		"key": "skirmisher",
		"value": "Skirmisher"
	},
	{
		"key": "smite",
		"value": "Smite"
	},
	{
		"key": "sound-of-the-drums",
		"value": "Sound of the Drums"
	},
	{
		"key": "speed-of-horse",
		"value": "Speed of Horse"
	},
	{
		"key": "standard-bearer",
		"value": "Standard Bearer"
	},
	{
		"key": "stone-spikes",
		"value": "Stone Spikes"
	},
	{
		"key": "superior-creations",
		"value": "Superior Creations"
	},
	{
		"key": "support",
		"value": "Support X"
	},
	{
		"key": "suppress-survival-instinct",
		"value": "Suppress Survival Instinct"
	},
	{
		"key": "sureshot",
		"value": "Sureshot"
	},
	{
		"key": "surprise-attack",
		"value": "Surprise Attack"
	},
	{
		"key": "surrounded-by-fear-and-dead-men",
		"value": "Surrounded by Fear and Dead Men"
	},
	{
		"key": "temper-plate",
		"value": "Temper Plate"
	},
	{
		"key": "tenacious",
		"value": "Tenacious"
	},
	{
		"key": "terrifying",
		"value": "Terrifying X"
	},
	{
		"key": "the-ardent-creed",
		"value": "The Ardent Creed"
	},
	{
		"key": "the-best-of-men",
		"value": "The Best of Men"
	},
	{
		"key": "the-directorate",
		"value": "The Directorate"
	},
	{
		"key": "the-first-blessing",
		"value": "The First Blessing"
	},
	{
		"key": "the-matriarch-walks",
		"value": "The Matriarch Walks"
	},
	{
		"key": "the-matriarch-walks-de",
		"value": "The Matriarch Walks"
	},
	{
		"key": "the-pursuit-of-aghm",
		"value": "The Pursuit of Aghm"
	},
	{
		"key": "the-sovereign-lineage",
		"value": "The Sovereign Lineage"
	},
	{
		"key": "the-spirit-shines",
		"value": "The Spirit Shines"
	},
	{
		"key": "the-tempered-creed",
		"value": "The Tempered Creed"
	},
	{
		"key": "elemental-potency",
		"value": "Elemental Potency"
	},
	{
		"key": "the-under-spire",
		"value": "The Under Spire"
	},
	{
		"key": "the-unnumbered-menagerie",
		"value": "The Unnumbered Menagerie"
	},
	{
		"key": "thegn",
		"value": "Thegn"
	},
	{
		"key": "thrill-of-the-kill",
		"value": "Thrill of the Kill"
	},
	{
		"key": "to-the-last-breath",
		"value": "To the Last Breath"
	},
	{
		"key": "torrential-fire",
		"value": "Torrential Fire"
	},
	{
		"key": "tourney-champion",
		"value": "Tourney Champion"
	},
	{
		"key": "tracker",
		"value": "Tracker"
	},
	{
		"key": "trample",
		"value": "Trample X"
	},
	{
		"key": "translocation",
		"value": "Translocation"
	},
	{
		"key": "unhallowed-ground",
		"value": "Unhallowed Ground"
	},
	{
		"key": "unholy-baptism",
		"value": "Unholy Baptism"
	},
	{
		"key": "unmake-armor",
		"value": "Unmake Armor"
	},
	{
		"key": "unstable-enhancement",
		"value": "Unstable Enhancement"
	},
	{
		"key": "unstoppable",
		"value": "Unstoppable"
	},
	{
		"key": "unsung",
		"value": "Unsung"
	},
	{
		"key": "untouchable",
		"value": "Untouchable"
	},
	{
		"key": "unyielding",
		"value": "Unyielding"
	},
	{
		"key": "vanguard",
		"value": "Vanguard"
	},
	{
		"key": "veterans",
		"value": "Veterans"
	},
	{
		"key": "veteran-sergeants",
		"value": "Veteran Sergeants"
	},
	{
		"key": "vision-of-conquest",
		"value": "Vision of Conquest"
	},
	{
		"key": "warbringer-s-light",
		"value": "Warbringer's Light"
	},
	{
		"key": "ward-preceptor",
		"value": "Ward Preceptor"
	},
	{
		"key": "white-waste-shaman",
		"value": "White Waste Shaman"
	},
	{
		"key": "wind-s-children",
		"value": "Wind's Children"
	},
	{
		"key": "wizard",
		"value": "Wizard X"
	},
	{
		"key": "wolf-aspect",
		"value": "Wolf"
	},
	{
		"key": "purposefully-mindless",
		"value": "Purposefully Mindless"
	},
	{
		"key": "flint-knapper",
		"value": "Flint Knapper"
	},
	{
		"key": "carrier-of-the-godflesh",
		"value": "Carrier of the Godflesh"
	},
	{
		"key": "army-of-lions",
		"value": "Army of Lions"
	},
	{
		"key": "infantry-tactics",
		"value": "Infantry Tactics"
	},
	{
		"key": "aggression-directive",
		"value": "Aggression Directive"
	},
	{
		"key": "clockwork-parade",
		"value": "Clockwork Parade"
	},
	{
		"key": "iron-stride",
		"value": "Iron Stride"
	},
	{
		"key": "othismos",
		"value": "Othismos"
	},
	{
		"key": "molon-labe",
		"value": "Molon Labe"
	},
	{
		"key": "temper-resolve",
		"value": "Temper Resolve"
	},
	{
		"key": "quench-blades",
		"value": "Quench Blades"
	},
	{
		"key": "trident-strike",
		"value": "Trident Strike"
	},
	{
		"key": "crucible-s-fire",
		"value": "Crucible's Fire"
	},
	{
		"key": "pike-formation",
		"value": "Pike Formation"
	},
	{
		"key": "automaton",
		"value": "Automaton"
	},
	{
		"key": "herald-of-the-forge-god",
		"value": "Herald of the Forge God"
	},
	{
		"key": "xypharchos",
		"value": "Xypharchos"
	},
	{
		"key": "lochagos",
		"value": "Lochagos"
	},
	{
		"key": "dorilates",
		"value": "Dorilates"
	},
	{
		"key": "seer",
		"value": "Seer"
	},
	{
		"key": "satyr-assassin",
		"value": "Satyr Assassin"
	},
	{
		"key": "standard-of-steel",
		"value": "Standard of Steel"
	},
	{
		"key": "regalia-of-the-empire",
		"value": "Regalia of the Empire"
	},
	{
		"key": "gilded-rampart",
		"value": "Gilded Rampart"
	},
	{
		"key": "the-shroud-of-st-lazarus",
		"value": "The Shroud of St. Lazarus"
	},
	{
		"key": "armor-of-dominion",
		"value": "Armor of Dominion"
	},
	{
		"key": "indomitable-plate",
		"value": "Indomitable Plate"
	},
	{
		"key": "bakkian-token",
		"value": "Bakkian Token"
	},
	{
		"key": "mask-of-eaklides",
		"value": "Mask of Eaklides"
	},
	{
		"key": "the-kiss-farewell",
		"value": "The Kiss Farewell"
	},
	{
		"key": "caledburn",
		"value": "Caledburn"
	},
	{
		"key": "the-flesh-cleaver",
		"value": "The Flesh Cleaver"
	},
	{
		"key": "laurean-lance",
		"value": "Laurean Lance"
	},
	{
		"key": "the-unwrought",
		"value": "The Unwrought"
	},
	{
		"key": "eye-of-akelus",
		"value": "Eye of Akelus"
	},
	{
		"key": "eye-of-akelus-de",
		"value": "Eye of Akelus"
	},
	{
		"key": "olefant-s-roar",
		"value": "Olefant's Roar"
	},
	{
		"key": "finite-state-apparatus",
		"value": "Finite-State Apparatus"
	},
	{
		"key": "finite-state-apparatus-de",
		"value": "Finite-State Apparatus"
	},
	{
		"key": "lady-s-favour",
		"value": "Lady's Favour"
	},
	{
		"key": "mantle-of-saint-nicholas",
		"value": "Mantle of Saint Nicholas"
	},
	{
		"key": "chapter-scroll",
		"value": "Chapter Scroll"
	},
	{
		"key": "elysian-fragment",
		"value": "Elysian Fragment"
	},
	{
		"key": "elysian-fragment-de",
		"value": "Elysian Fragment"
	},
	{
		"key": "hopes-and-prayers",
		"value": "Hopes and Prayers"
	},
	{
		"key": "art-of-war",
		"value": "Art of War"
	},
	{
		"key": "rally-to-me",
		"value": "Rally to Me!"
	},
	{
		"key": "eccentric-fighting-style",
		"value": "Eccentric Fighting Style"
	},
	{
		"key": "long-lineage",
		"value": "Long Lineage"
	},
	{
		"key": "expose-weakness",
		"value": "Expose Weakness"
	},
	{
		"key": "marksmanship",
		"value": "Marksmanship"
	},
	{
		"key": "disorienting-strikes",
		"value": "Disorienting Strikes"
	},
	{
		"key": "overkill",
		"value": "Overkill"
	},
	{
		"key": "glimmer-of-faith",
		"value": "Glimmer of Faith"
	},
	{
		"key": "magus",
		"value": "Magus"
	},
	{
		"key": "focused",
		"value": "Focused"
	},
	{
		"key": "pheromantic-override",
		"value": "Pheromantic Override"
	},
	{
		"key": "pheromantic-override-de",
		"value": "Pheromantic Override"
	},
	{
		"key": "cascading-degeneration",
		"value": "Cascading Degeneration"
	},
	{
		"key": "degenerative-aura",
		"value": "Degenerative Aura"
	},
	{
		"key": "infiltrator-variant",
		"value": "Infiltrator Variant"
	},
	{
		"key": "architect-s-touch",
		"value": "Architect's Touch"
	},
	{
		"key": "biotic-hive",
		"value": "Biotic Hive"
	},
	{
		"key": "heightened-reflexes",
		"value": "Heightened Reflexes"
	},
	{
		"key": "venom",
		"value": "Venom"
	},
	{
		"key": "adaptive-evolution",
		"value": "Adaptive Evolution"
	},
	{
		"key": "biotic-wellspring",
		"value": "Biotic Wellspring"
	},
	{
		"key": "enhanced-reactions",
		"value": "Enhanced Reactions"
	},
	{
		"key": "redundant-biomantic-structure",
		"value": "Redundant Biomantic Structure"
	},
	{
		"key": "marksman-variant",
		"value": "Marksman Variant"
	},
	{
		"key": "adaptive-senses",
		"value": "Adaptive Senses"
	},
	{
		"key": "biomantic-plague-node",
		"value": "Biomantic Plague Node"
	},
	{
		"key": "burrowing-parasites",
		"value": "Burrowing Parasites"
	},
	{
		"key": "command-pheromones",
		"value": "Command Pheromones"
	},
	{
		"key": "adrenal-surge",
		"value": "Adrenal Surge"
	},
	{
		"key": "avatar-projection",
		"value": "Avatar Projection"
	},
	{
		"key": "sensory-augmentation",
		"value": "Sensory Augmentation"
	},
	{
		"key": "ablative-flesh",
		"value": "Ablative Flesh"
	},
	{
		"key": "mindless-protectors",
		"value": "Mindless Protectors"
	},
	{
		"key": "attracting-pheromones",
		"value": "Attracting Pheromones"
	},
	{
		"key": "biomass-abundance",
		"value": "Biomass Abundance"
	},
	{
		"key": "eagle-eye",
		"value": "Eagle Eye"
	},
	{
		"key": "additional-neural-receptors",
		"value": "Additional Neural Receptors"
	},
	{
		"key": "fleshcarver",
		"value": "Fleshcarver"
	},
	{
		"key": "plaguelord",
		"value": "Plaguelord"
	},
	{
		"key": "synaptic-coordination",
		"value": "Synaptic Coordination"
	},
	{
		"key": "until-we-have-to",
		"value": "'Until We Have To'"
	},
	{
		"key": "mnemancer-s-eye",
		"value": "Mnemancer's Eye"
	},
	{
		"key": "the-crown-of-ushkelodh",
		"value": "The Crown of Ushkelodh"
	},
	{
		"key": "arena-champion",
		"value": "Arena Champion"
	},
	{
		"key": "champion-s-horns",
		"value": "Champion's Horns"
	},
	{
		"key": "steel-enhancements",
		"value": "Steel Enhancements"
	},
	{
		"key": "memory-of-stone",
		"value": "Memory of Stone"
	},
	{
		"key": "draegbhrud",
		"value": "Draegbhrud"
	},
	{
		"key": "flaming-weapon",
		"value": "Flaming Weapon"
	},
	{
		"key": "perfectly-balanced",
		"value": "Perfectly Balanced"
	},
	{
		"key": "obsidian-grafts",
		"value": "Obsidian Grafts"
	},
	{
		"key": "gifted-in-fire",
		"value": "Gifted in Fire"
	},
	{
		"key": "slayer-s-brand",
		"value": "Slayer's Brand"
	},
	{
		"key": "the-flame-flickers",
		"value": "The Flame Flickers"
	},
	{
		"key": "remembered",
		"value": "Remembered"
	},
	{
		"key": "remembrance-of-the-core",
		"value": "Remembrance of the Core"
	},
	{
		"key": "remembrance-of-the-core-de",
		"value": "Remembrance of the Core"
	},
	{
		"key": "invocation-of-the-shattering",
		"value": "Invocation of the Shattering"
	},
	{
		"key": "the-shattering",
		"value": "The Shattering"
	},
	{
		"key": "heart-of-the-mountain",
		"value": "Heart of the Mountain"
	},
	{
		"key": "heart-of-the-mountain-de",
		"value": "Heart of the Mountain"
	},
	{
		"key": "tempered-goad",
		"value": "Tempered Goad"
	},
	{
		"key": "tempered-goad-action",
		"value": "Tempered Goad"
	},
	{
		"key": "graft-of-fire",
		"value": "Graft of Fire"
	},
	{
		"key": "memory-of-breath",
		"value": "Memory of Breath"
	},
	{
		"key": "forged-in-centuries-of-conflict",
		"value": "Forged in Centuries of Conflict"
	},
	{
		"key": "call-the-mnemancers",
		"value": "Call the Mnemancers!"
	},
	{
		"key": "baptized-in-combat",
		"value": "Baptized in Combat"
	},
	{
		"key": "fuelled-by-the-furnace",
		"value": "Fuelled by the Furnace"
	},
	{
		"key": "flaming-oratory",
		"value": "Flaming Oratory"
	},
	{
		"key": "hellbringer-sorcerer",
		"value": "Hellbringer Sorcerer"
	},
	{
		"key": "lava-shots",
		"value": "Lava Shots"
	},
	{
		"key": "raven-messengers",
		"value": "Raven Messengers"
	},
	{
		"key": "coils-of-the-serpent",
		"value": "Coils of the Serpent"
	},
	{
		"key": "figurehead-of-the-naglfar",
		"value": "Figurehead of the Naglfar"
	},
	{
		"key": "fearsome-reputation",
		"value": "Fearsome Reputation"
	},
	{
		"key": "jarngreipr",
		"value": "Járngreipr"
	},
	{
		"key": "ty-jokull-the-rimeheart",
		"value": "Ty Jokull, the Rimeheart"
	},
	{
		"key": "massive-frame",
		"value": "Massive Frame"
	},
	{
		"key": "vinda-the-dancer",
		"value": "Vinda, the Dancer"
	},
	{
		"key": "rjoda-the-red-bringer",
		"value": "Rjóða, the Red Bringer"
	},
	{
		"key": "reyngeir-the-god-spear",
		"value": "Reyngeir, the God Spear"
	},
	{
		"key": "the-broken-blade",
		"value": "The Broken Blade"
	},
	{
		"key": "vanquisher-of-monsters",
		"value": "Vanquisher of Monsters"
	},
	{
		"key": "golden-torc",
		"value": "Golden Torc"
	},
	{
		"key": "the-horn-of-hjoldgar",
		"value": "The Horn of Hjoldgar"
	},
	{
		"key": "mistcaller-s-raiment",
		"value": "Mistcaller's Raiment"
	},
	{
		"key": "heriolf-s-folly",
		"value": "Heriolf's Folly"
	},
	{
		"key": "prize-silver",
		"value": "Prize Silver"
	},
	{
		"key": "herald-of-the-storm",
		"value": "Herald of the Storm"
	},
	{
		"key": "herald-of-the-storm-de",
		"value": "Herald of the Storm"
	},
	{
		"key": "runes-of-wisdom",
		"value": "Runes of Wisdom"
	},
	{
		"key": "runes-of-wisdom-de",
		"value": "Runes of Wisdom"
	},
	{
		"key": "runes-of-fate",
		"value": "Runes of Fate"
	},
	{
		"key": "lessons-of-the-jotun-war",
		"value": "Lessons of the Jotun War"
	},
	{
		"key": "wealth-of-a-hundred-raids",
		"value": "Wealth of a Hundred Raids"
	},
	{
		"key": "the-hunt-s-instinct",
		"value": "The Hunt's Instinct"
	},
	{
		"key": "watchful-eye-of-the-high-kings",
		"value": "Watchful Eye of the High Kings"
	},
	{
		"key": "headhunter",
		"value": "Headhunter"
	},
	{
		"key": "curse-of-the-vargr",
		"value": "Curse of the Vargr"
	},
	{
		"key": "sacrificial-lamb",
		"value": "Sacrificial Lamb"
	},
	{
		"key": "rune-of-shielding",
		"value": "Rune of Shielding"
	},
	{
		"key": "roar-of-the-tribe",
		"value": "Roar of the Tribe"
	},
	{
		"key": "death-s-gaze",
		"value": "Death's Gaze"
	},
	{
		"key": "scars-of-endekar",
		"value": "Scars of Endekar"
	},
	{
		"key": "the-blood-horn",
		"value": "The Blood Horn"
	},
	{
		"key": "beads-of-death",
		"value": "Beads of Death"
	},
	{
		"key": "hide-of-the-silent-hunter",
		"value": "Hide of the Silent Hunter"
	},
	{
		"key": "kill-marks",
		"value": "Kill Marks"
	},
	{
		"key": "heartsblood-draught",
		"value": "Heartsblood Draught"
	},
	{
		"key": "brood-of-omgorah",
		"value": "Brood of Omgorah"
	},
	{
		"key": "death-s-reach",
		"value": "Death's Reach"
	},
	{
		"key": "beast-drums",
		"value": "Beast Drums"
	},
	{
		"key": "kiss-of-the-dilosaur",
		"value": "Kiss of the Dilosaur"
	},
	{
		"key": "glaives-of-iskarant",
		"value": "Glaives of Iskarant"
	},
	{
		"key": "the-hidden-fang",
		"value": "The Hidden Fang"
	},
	{
		"key": "dreamcatcher",
		"value": "Dreamcatcher"
	},
	{
		"key": "mantle-of-the-devoted",
		"value": "Mantle of the Devoted"
	},
	{
		"key": "eritu-s-mark",
		"value": "Eritu's Mark"
	},
	{
		"key": "tribal-spear",
		"value": "Tribal Spear"
	},
	{
		"key": "touched-by-the-goddess",
		"value": "Touched by the Goddess"
	},
	{
		"key": "sacred-censer",
		"value": "Sacred Censer"
	},
	{
		"key": "essence-of-the-phonopteryx",
		"value": "Essence of the Phonopteryx"
	},
	{
		"key": "primordial-splinter",
		"value": "Primordial Splinter"
	},
	{
		"key": "loyalty",
		"value": "Loyalty"
	},
	{
		"key": "wasteland-adder",
		"value": "Wasteland Adder"
	},
	{
		"key": "apex-master",
		"value": "Apex Master"
	},
	{
		"key": "one-voice",
		"value": "One Voice"
	},
	{
		"key": "sanctified-labaron",
		"value": "Sanctified Labaron"
	},
	{
		"key": "caelestine-banner",
		"value": "Caelestine Banner"
	},
	{
		"key": "vexilla-of-the-lost",
		"value": "Vexilla of the Lost"
	},
	{
		"key": "legio-i-primigenia",
		"value": "Legio I 'Primigenia'"
	},
	{
		"key": "semion-of-the-legion",
		"value": "Semion of the Legion"
	},
	{
		"key": "cuirass-of-hazlia-s-shadow",
		"value": "Cuirass of Hazlia's Shadow"
	},
	{
		"key": "aventine-armor",
		"value": "Aventine Armor"
	},
	{
		"key": "armor-of-the-living-saint",
		"value": "Armor of the Living Saint"
	},
	{
		"key": "imperial-oplon",
		"value": "Imperial Oplon"
	},
	{
		"key": "anastegma-brand-of-the-faithless",
		"value": "Anastegma, Brand of the Faithless"
	},
	{
		"key": "skofnung",
		"value": "Skofnung"
	},
	{
		"key": "calamitas-blade-of-the-caelesor",
		"value": "Calamitas, Blade of the Caelesor"
	},
	{
		"key": "eleutherea-giver-of-mercy",
		"value": "Eleutherea, Giver of Mercy"
	},
	{
		"key": "blasphemous-soma",
		"value": "Blasphemous Soma"
	},
	{
		"key": "unholy-sacrament",
		"value": "Unholy Sacrament"
	},
	{
		"key": "consecrated-mitre",
		"value": "Consecrated Mitre"
	},
	{
		"key": "the-reaping-crook",
		"value": "The Reaping Crook"
	},
	{
		"key": "kentarch",
		"value": "Kentarch"
	},
	{
		"key": "aura-of-malice",
		"value": "Aura of Malice"
	},
	{
		"key": "eternal-discipline",
		"value": "Eternal Discipline"
	},
	{
		"key": "what-we-did-in-life-will-echo-in-eternity",
		"value": "What we did in Life will Echo in Eternity"
	},
	{
		"key": "gladiator",
		"value": "Gladiator"
	},
	{
		"key": "implacable",
		"value": "Implacable"
	},
	{
		"key": "unholy-mastery",
		"value": "Unholy Mastery"
	},
	{
		"key": "schemophore",
		"value": "Schemophore"
	},
	{
		"key": "viaticum",
		"value": "Viaticum"
	},
	{
		"key": "devoted-to-hazlia",
		"value": "Devoted to Hazlia"
	},
	{
		"key": "standard-of-last-oration",
		"value": "Standard of Last Oration"
	},
	{
		"key": "aristia",
		"value": "Aristia"
	},
	{
		"key": "primodynamic-globe",
		"value": "Primodynamic Globe"
	},
	{
		"key": "chrysaor-s-helmet",
		"value": "Chrysaor's Helmet"
	},
	{
		"key": "living-breastplate",
		"value": "Living Breastplate"
	},
	{
		"key": "blades-of-eakides",
		"value": "Blades of Eakides"
	},
	{
		"key": "atalanta-s-spear",
		"value": "Atalanta's Spear"
	},
	{
		"key": "featherblade",
		"value": "Featherblade"
	},
	{
		"key": "inscription-of-lighter-alloys",
		"value": "Inscription of Lighter Alloys"
	},
	{
		"key": "mobility",
		"value": "Mobility"
	},
	{
		"key": "inscription-of-impact-resistance",
		"value": "Inscription of Impact Resistance"
	},
	{
		"key": "resist",
		"value": "Resist"
	},
	{
		"key": "inscription-of-balance",
		"value": "Inscription of Balance"
	},
	{
		"key": "alternate-soma",
		"value": "Alternate Soma"
	},
	{
		"key": "anticythian-alloy-gears",
		"value": "Anticythian Alloy Gears"
	},
	{
		"key": "alternate-programming ",
		"value": "Alternate Programming "
	},
	{
		"key": "resonance-receptors",
		"value": "Resonance Receptors"
	},
	{
		"key": "hephaestian-alloys",
		"value": "Hephaestian Alloys"
	},
	{
		"key": "expert-scouts",
		"value": "Expert Scouts"
	},
	{
		"key": "initiative",
		"value": "Initiative"
	},
	{
		"key": "combined-arms-drills",
		"value": "Combined Arms Drills"
	},
	{
		"key": "brace-for-impact",
		"value": "Brace for Impact"
	},
	{
		"key": "on-your-feet",
		"value": "On Your Feet"
	},
	{
		"key": "fire-first-aim-later",
		"value": "Fire First, Aim Later"
	},
	{
		"key": "aim-for-the-head",
		"value": "Aim For the Head"
	},
	{
		"key": "suppress-pain",
		"value": "Suppress Pain"
	},
	{
		"key": "overtax-nervous-system",
		"value": "Overtax Nervous System"
	},
	{
		"key": "disperse",
		"value": "Disperse"
	},
	{
		"key": "purity-of-purpose",
		"value": "Purity of Purpose"
	},
	{
		"key": "rapid-displacement",
		"value": "Rapid Displacement"
	},
	{
		"key": "violence-of-action",
		"value": "Violence of Action"
	},
	{
		"key": "way-of-the-hunt-1",
		"value": "Hunt 1"
	},
	{
		"key": "way-of-the-hunt-2",
		"value": "Hunt 2"
	},
	{
		"key": "break-their-lines",
		"value": "Break Their Lines"
	},
	{
		"key": "combat-directives",
		"value": "Combat Directives"
	},
	{
		"key": "alternate-programming",
		"value": "Alternate Programming "
	},
	{
		"key": "combat-directives-alt",
		"value": "Combat Directives"
	},
	{
		"key": "battlefield-tactics",
		"value": "Battlefield Tactics"
	},
	{
		"key": "battlefield-orders",
		"value": "Battlefield Orders"
	},
	{
		"key": "scorpion",
		"value": "Scorpion"
	},
	{
		"key": "finest-cavalry",
		"value": "Finest Cavalry"
	},
	{
		"key": "march-of-giants",
		"value": "March of Giants"
	},
	{
		"key": "swarm",
		"value": "Swarm"
	},
	{
		"key": "towering-presence",
		"value": "Towering Presence"
	},
	{
		"key": "brontoskalp",
		"value": "Brontoskalp"
	},
	{
		"key": "wedge",
		"value": "Wedge!"
	},
	{
		"key": "ride-the-lightning",
		"value": "Ride the Lightning"
	},
	{
		"key": "elder-brontoskalps",
		"value": "Elder Brontoskalps"
	},
	{
		"key": "ice-flesh-and-blood",
		"value": "Ice, Flesh and Blood"
	},
	{
		"key": "reprocessing-agents",
		"value": "Reprocessing Agents"
	},
	{
		"key": "induced-lethargy",
		"value": "Induced Lethargy"
	},
	{
		"key": "critical-field",
		"value": "Critical Field"
	},
	{
		"key": "indifferent-towards-life",
		"value": "Indifferent Towards Life"
	},
	{
		"key": "ferric-embrace",
		"value": "Ferric Embrace"
	},
	{
		"key": "prophecy-unfulfilled",
		"value": "Prophecy Unfulfilled"
	},
	{
		"key": "bergont-raegh",
		"value": "Bergont Raegh"
	},
	{
		"key": "elemental-confluence",
		"value": "Elemental Confluence"
	},
	{
		"key": "omnipotence",
		"value": "Omnipotence"
	},
	{
		"key": "arcane-dervish",
		"value": "Arcane Dervish"
	},
	{
		"key": "word-of-the-voice",
		"value": "Word of the Voice"
	},
	{
		"key": "fear-and-discipline",
		"value": "Fear and Discipline"
	},
	{
		"key": "banner-of-elemental-dominion",
		"value": "Banner of Elemental Dominion"
	},
	{
		"key": "elemental-dominance",
		"value": "Elemental Dominance"
	},
	{
		"key": "icon-of-transmutation",
		"value": "Icon of Transmutation"
	},
	{
		"key": "icon-of-transmutation-de",
		"value": "Fear and Discipline"
	},
	{
		"key": "elemental-tether",
		"value": "Elemental Tether"
	},
	{
		"key": "elemental-tether-de",
		"value": "Elemental Tether"
	},
	{
		"key": "dancing-scimitar",
		"value": "Dancing Scimitar"
	},
	{
		"key": "dancing-scimitar-de",
		"value": "Dancing Scimitar"
	},
	{
		"key": "shulaat",
		"value": "Shu'laat"
	},
	{
		"key": "prijm-khanjar",
		"value": "Prijm Khanjar"
	},
	{
		"key": "prijm-khanjar-spell",
		"value": "Prijm Khanjar"
	},
	{
		"key": "niyantran",
		"value": "Niyantran"
	},
	{
		"key": "jadoo-kavach",
		"value": "Jadoo Kavach"
	},
	{
		"key": "parivartan",
		"value": "Parivartan"
	},
	{
		"key": "eye-of-the-blazing-tempest",
		"value": "Eye of the Blazing Tempest"
	},
	{
		"key": "prince-of-the-setting-moon",
		"value": "Prince of the Setting Moon"
	},
	{
		"key": "recorder-of-all-deeds",
		"value": "Recorder of all Deeds"
	},
	{
		"key": "vizier-of-the-morning-star",
		"value": "Vizier of the Morning Star"
	},
	{
		"key": "best-money-can-buy",
		"value": "Best Money can Buy"
	},
	{
		"key": "favored-of-hormus",
		"value": "Favored of Hormus"
	},
	{
		"key": "lord-of-conflict",
		"value": "Lord of Conflict"
	},
	{
		"key": "elemental-feedback",
		"value": "Elemental Feedback"
	},
	{
		"key": "bound-to-the-elements",
		"value": "Bound to the Elements"
	},
	{
		"key": "elemental-projection",
		"value": "Elemental Projection"
	},
	{
		"key": "locus-of-the-elements",
		"value": "Locus of the Elements"
	},
	{
		"key": "intrusive-thoughts",
		"value": "Intrusive Thoughts"
	},
	{
		"key": "incite-rage",
		"value": "Incite Rage"
	},
	{
		"key": "conflagration",
		"value": "Conflagration"
	},
	{
		"key": "fiery-dominion",
		"value": "Fiery Dominion"
	},
	{
		"key": "far-sight",
		"value": "Far Sight"
	},
	{
		"key": "sayf",
		"value": "Sayf"
	},
	{
		"key": "spiteful-winds",
		"value": "Spiteful Winds"
	},
	{
		"key": "burn-to-cinders",
		"value": "Burn to Cinders"
	},
	{
		"key": "scorching-scirocco",
		"value": "Scorching Scirocco"
	},
	{
		"key": "wreathed-in-flames",
		"value": "Wreathed in Flames"
	},
	{
		"key": "lifting-winds",
		"value": "Lifting Winds"
	},
	{
		"key": "lightning-bolt",
		"value": "Lightning Bolt"
	},
	{
		"key": "homing-winds",
		"value": "Homing Winds"
	},
	{
		"key": "cauterize",
		"value": "Cauterize"
	},
	{
		"key": "ignite",
		"value": "Ignite"
	},
	{
		"key": "searing-sandstorm",
		"value": "Searing Sandstorm"
	},
	{
		"key": "air-step",
		"value": "Air Step"
	},
	{
		"key": "tailwind",
		"value": "Tailwind"
	},
	{
		"key": "wildfire",
		"value": "Wildfire"
	},
	{
		"key": "molten-blades",
		"value": "Molten Blades"
	},
	{
		"key": "wreathed-in-fire",
		"value": "Wreathed in Fire"
	},
	{
		"key": "storm-s-wrath",
		"value": "Storm's Wrath"
	},
	{
		"key": "wind-kissed-blades",
		"value": "Wind Kissed Blades"
	},
	{
		"key": "infernal-marker",
		"value": "Infernal Marker"
	},
	{
		"key": "infernal-branding",
		"value": "Infernal Branding"
	},
	{
		"key": "aetheric-marker",
		"value": "Aetheric Marker"
	},
	{
		"key": "aetheric-guidance",
		"value": "Aetheric Guidance"
	},
	{
		"key": "arrogance",
		"value": "Arrogance"
	},
	{
		"key": "last-word",
		"value": "Last Word"
	},
	{
		"key": "hubris",
		"value": "Hubris"
	},
	{
		"key": "whirling-storm",
		"value": "Whirling Storm"
	},
	{
		"key": "eye-of-the-storm",
		"value": "Eye of the Storm"
	},
	{
		"key": "arcane-conduit",
		"value": "Arcane Conduit"
	},
	{
		"key": "born-of-flame",
		"value": "Born of Flame"
	},
	{
		"key": "born-of-air",
		"value": "Born of Air"
	},
	{
		"key": "elemental",
		"value": "Elemental"
	},
	{
		"key": "arcane-relay",
		"value": "Arcane Relay"
	},
	{
		"key": "aimed-shot",
		"value": "Aimed Shot"
	},
	{
		"key": "forward-force",
		"value": "Forward Force"
	},
	{
		"key": "weapon-plartform",
		"value": "Weapon Platform"
	},
	{
		"key": "subdermal-keratin-induction",
		"value": "Subdermal Keratin Induction"
	},
	{
		"key": "thunderous-assault",
		"value": "Thunderous Assault"
	},
	{
		"key": "discordance",
		"value": "Discordance"
	},
	{
		"key": "resonance",
		"value": "Resonance"
	},
	{
		"key": "unstable-alloys",
		"value": "Unstable Alloys"
	},
	{
		"key": "magnetic-conduit",
		"value": "Magnetic Conduit"
	},
	{
		"key": "feric-throne",
		"value": "Feric Throne"
	},
	{
		"key": "fear",
		"value": "Fear"
	},
	{
		"key": "rancor",
		"value": "Rancor"
	},
	{
		"key": "aghm-to-be-gained!",
		"value": "Aghm to be Gained!"
	},
	{
		"key": "from-the-front",
		"value": "From the Front"
	},
	{
		"key": "benediction-of-the-black-sun",
		"value": "Benediction of the Black Sun"
	},
	{
		"key": "undying-devotion",
		"value": "Undying Devotion"
	},
	{
		"key": "forced-march",
		"value": "Forced March"
	},
	{
		"key": "graceful-combatant",
		"value": "Graceful Combatant"
	},
	{
		"key": "hoarfrost",
		"value": "Hoarfrost"
	},
	{
		"key": "dark-hand-of-the-scholae",
		"value": "Dark Hand of the Scholae"
	},
	{
		"key": "close-the-gap",
		"value": "Close the Gap!"
	},
	{
		"key": "torque",
		"value": "Torque"
	},
	{
		"key": "stoic",
		"value": "Stoic"
	},
	{
		"key": "voice-of-conquest",
		"value": "Voice of Conquest"
	},
	{
		"key": "condensed-propellant",
		"value": "Condensed Propellant"
	},
	{
		"key": "exploit-flanks",
		"value": "Exploit Flanks"
	},
	{
		"key": "tontorr-rider",
		"value": "Tontorr Rider"
	},
	{
		"key": "mounted-general",
		"value": "Mounted General"
	},
	{
		"key": "moaghm-dohr",
		"value": "Moaghm Dohr!"
	},
	{
		"key": "watch-and-learn",
		"value": "Watch and Learn"
	},
	{
		"key": "ruthless-sovereigns",
		"value": "Ruthless Sovereigns"
	},
	{
		"key": "hunter-killer-teams",
		"value": "Hunter-Killer Teams"
	},
	{
		"key": "descendant-of-the-brazen-race",
		"value": "Descendant of the Brazen Race"
	},
	{
		"key": "blood-of-the-forge-god",
		"value": "Blood of the Forge God"
	},
	{
		"key": "mask-of-eris",
		"value": "Mask of Eris"
	},
	{
		"key": "helm-of-nike",
		"value": "Helm of Nike"
	},
	{
		"key": "entropic-shield",
		"value": "Elemental Vortex"
	},
	{
		"key": "minor-elemental-missile",
		"value": "Elemental Missile"
	},
	{
		"key": "bringing-back-the-children-of-the-north",
		"value": "Bringing Back the Children of the North"
	},
	{
		"key": "seizing-a-new-future",
		"value": "Seizing a New Future"
	},
	{
		"key": "crushing-avalanche",
		"value": "Crushing Avalanche"
	},
	{
		"key": "lord-of-titans",
		"value": "Lord of Titans"
	},
	{
		"key": "ancient-tactician",
		"value": "Ancient Tactician"
	},
	{
		"key": "bringing-back-the-children-of-fire",
		"value": "Bringing Back the Children of Fire"
	},
	{
		"key": "dori",
		"value": "Dori"
	},
	{
		"key": "skorge",
		"value": "Skorge"
	},
	{
		"key": "eminence",
		"value": "Eminence"
	},
]